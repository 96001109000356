import { CardProduct } from '@/molecules';

import { badgeTransformer } from '@/lib/graphql/transformers';
import { woosbDiscountTransformer } from '@/lib/graphql/transformers/product/woosb';
import { useBundle } from '@/lib/hooks';
import {
  getPercentageDiscount,
  gtmPushSelectItem,
  isBundle,
  isConfigurableBundle,
  isSimple,
  isVariable,
  normalizeAlgoliaItem,
  toCurrency,
} from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { Woosb } from '@/lib/graphql/transformers/product/woosb';
import type { TypeProduct } from '@/types';
import type { FunctionComponent } from 'react';
import type { TypeCardProductAlgoliaProps } from './types';

const discountFormatter = (discount: Woosb['discount']) => {
  if (discount.type === 'percentage') return `-${discount.percentage}%`;
  if (discount.type === 'amount') return `-${toCurrency(discount.amount ?? 0)}`;
};

/**
 * CardProductAlgolia
 */
export const CardProductAlgolia: FunctionComponent<
  TypeCardProductAlgoliaProps
> = ({
  hit,
  type = 'COMPLETE',
  sendEvent,
  itemListName,
  itemIndex,
  numColumns,
}: TypeCardProductAlgoliaProps) => {
  const IS_FALSE_VARIABLE = isVariable(hit.type) && hit.variations.length === 1;
  const productID = IS_FALSE_VARIABLE ? hit.variations?.at(0)?.id : hit.id;
  const isConfigurable = isConfigurableBundle(
    hit.type,
    hit.woosbOptionalProducts,
  );
  const productType: TypeProduct = IS_FALSE_VARIABLE
    ? 'SIMPLE'
    : isBundle(hit.type) &&
        !hit.woosb_items?.every(
          (item) => isSimple(item.type) || item.type === 'variation',
        ) &&
        !isConfigurable
      ? 'VARIABLE'
      : hit.type;
  const regularPrice = hit.regular_price
    ? hit.regular_price
    : hit.variations?.at(0)?.regular_price || hit.regular_price;
  const salePrice = hit.sale_price
    ? hit.sale_price
    : hit.variations[0]?.sale_price || hit.sale_price;
  const box = hit?.card?.box;
  const hasBox = Boolean(box && box.active);
  const imageSize = hasBox ? 'small' : numColumns > 4 ? 'medium' : 'large';

  const onClick = () => {
    gtmPushSelectItem({
      item: normalizeAlgoliaItem({ item: hit, itemListName, index: itemIndex }),
      itemListName,
      index: itemIndex,
    });
  };

  const discountBundle = woosbDiscountTransformer(hit);
  // TODO: Enviar a algolia el descuento del bundle cuando depende de la cantidad
  const discount = isConfigurable
    ? discountFormatter(discountBundle)
    : `-${getPercentageDiscount(regularPrice, salePrice)}%`;

  // TODO: refactorizar sin el uso del hook
  const bundle = useBundle();

  return (
    <CardProduct
      href={hit.path}
      type={productType}
      productID={productID}
      stock={hit.stock_status}
      hit={hit}
      {...(IS_FALSE_VARIABLE && {
        minQtyOrder: hit?.variations[0]?.minQtyOrder ?? 1,
      })}
      sendEvent={sendEvent}
      {...(hasBox && { box })}
      onClick={onClick}
      isConfigurable={isConfigurable}
      editable={type === 'COMPLETE'}
      {...(hit?.seller_info && {
        hasFreeShipping: hit.seller_info?.hasFreeShipping,
      })}
      {...(hit?.woosb_min_qty && { woosbMinQty: hit.woosb_min_qty })}
      {...(!!hit.default_woosb && {
        woosbData: {
          ...hit.default_woosb,
          image: {
            src: hit.default_woosb.image.src,
            alt: hit.default_woosb.image.alt,
          },
        },
      })}
      gtm={{
        index: itemIndex,
        listName: itemListName,
      }}
    >
      <CardProduct.Image
        src={
          hit?.card?.image?.src || hit.featured_image?.src || FallBackIMG.src
        }
        alt={hit?.card?.image?.alt || hit.featured_image?.alt || hit.name}
        size={imageSize}
        {...(hit?.card?.backgroundColor
          ? { backgroundColor: hit?.card?.backgroundColor }
          : {})}
      />

      {hit.primary_category && (
        <CardProduct.Category
          href={hit.primary_category.path.replace('categoria-producto/', '')}
        >
          {hit.primary_category.name}
        </CardProduct.Category>
      )}

      {hit?.card?.badge?.active && (
        <CardProduct.Badge
          {...badgeTransformer(hit?.card?.badge, hit?.card?.life_style)}
        >
          {hit.card.badge.text}
        </CardProduct.Badge>
      )}

      {type === 'COMPLETE' && hit.on_sale && !bundle && (
        <CardProduct.Badge
          {...badgeTransformer(undefined, hit?.card?.life_style)}
        >
          {discount}
        </CardProduct.Badge>
      )}

      <CardProduct.Name>
        {hit?.card?.name ? hit.card.name : hit.name}
      </CardProduct.Name>

      {hit.seller && hit.seller.length > 0 && (
        <CardProduct.Seller
          {...(hit?.seller_info &&
            hit.seller_info?.slug && {
              slug: hit.seller_info.slug,
            })}
        >
          {hit.seller[0]}
        </CardProduct.Seller>
      )}

      {type === 'COMPLETE' && hit.on_sale && !bundle && !isConfigurable && (
        <CardProduct.PriceRegular>
          {toCurrency(regularPrice)}
        </CardProduct.PriceRegular>
      )}

      {type === 'COMPLETE' && !isConfigurable && (
        <CardProduct.PriceDiscounted>
          {toCurrency(
            hit.price
              ? bundle
                ? hit.regular_price
                : hit.price
              : hit.variations[0]?.price || hit.price,
          )}
        </CardProduct.PriceDiscounted>
      )}
    </CardProduct>
  );
};

CardProductAlgolia.displayName = 'CardProductAlgolia';
